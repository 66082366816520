<template>
    <div class="trustpilot_box ">
        <div class="trust_intro_flex">
            <div class="trust_title">
                <div class="trust_icon">
                    <img width="144" height="31" src="~/assets/assets/trustpilotlogo.svg" alt="trustpilot logo svg">
                </div>
         
                <p class="mb-0">Trustpilot lets customers review <br> businesses they’ve experienced</p>
            </div>
            <div class="trust_count">
                <div class="star_box mb-2">
                    <img width="30" height="30" src="~/assets/assets/trustpilot.svg" alt="trustpilot logo svg">
                    <img width="35" height="35" src="~/assets/assets/trustpilot.svg" alt="trustpilot logo svg">
                    <img width="40" height="40" src="~/assets/assets/trustpilot.svg" alt="trustpilot logo svg">
                    <img width="35" height="35" src="~/assets/assets/trustpilot.svg" alt="trustpilot logo svg">
                    <img width="30" height="30" src="~/assets/assets/trustpilot.svg" alt="trustpilot logo svg">
                </div>
                <h3 class="mb-1">Excellent</h3>
                <a rel="nofollow noopener" target="_blank"
                    href="https://www.trustpilot.com/review/friendlydiamonds.com">500+ reviews</a>
            </div>
        </div>

        <div class="trust_review_block">
            <div class="new_trust_intro_flex">
                <div class="new_trust_title">
                    <div class="trust_icon">
                        <img width="144" height="31" src="~/assets/assets/trustpilotlogo.svg" alt="trustpilot logo svg">
                    </div>
                    <div class="para">
                        <p class="">Trustpilot lets customers review businesses they’ve experienced</p>
                    </div>
                </div>
                <div class="new_trust_count">
                    <div class="star_box mb-2">
                        <img width="30" height="30" src="~/assets/assets/trustpilot.svg" alt="trustpilot logo svg">
                        <img width="35" height="35" src="~/assets/assets/trustpilot.svg" alt="trustpilot logo svg">
                        <img width="40" height="40" src="~/assets/assets/trustpilot.svg" alt="trustpilot logo svg">
                        <img width="35" height="35" src="~/assets/assets/trustpilot.svg" alt="trustpilot logo svg">
                        <img width="30" height="30" src="~/assets/assets/trustpilot.svg" alt="trustpilot logo svg">
                    </div>
                    <h3 class="mb-1">Excellent</h3>
                    <a rel="nofollow noopener" target="_blank"
                        href="https://www.trustpilot.com/review/friendlydiamonds.com">500+ reviews</a>
                </div>
            </div>
            <div class="box2">
                <swiper ref="c1" :modules="[SwiperAutoplay]"   
                :autoplay="{
                    delay: 2500,
                    disableOnInteraction: false,
                }" 
                :breakpoints="{
                    '0': {
                        slidesPerView: 1.2,
                        slidesPerGroup: 1,
                    },
                    '512': {
                        slidesPerView: 2,
                        slidesPerGroup: 1,
                    },
                    '901': {
                        slidesPerView: 2.5,
                        slidesPerGroup: 1,
                    },
                    '1025': {
                        slidesPerView: 3.5,
                        slidesPerGroup: 1,
                    }
                }" class="trustpilot__swiper">
                    <swiper-slide v-for="item in trustpilot" :key="item.id" class="py-3 ">
                        <div class="slide_box h-100">
                            <TrustpilotReviewBox :data="item" />
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </div>
</template>

<script setup>
// const skeleton = ref(true);

const trustpilot = [
    {
        id: 1,
        name: 'Adam M',
        date: 'February 01, 2024',
        // place: 'US',
        rating: 5,
        verified: true,
        title: 'Amazing service from start to finish',
        description: 'Customer service and responsive was top tier. The final product exceeded my lofty expectations. I will definitely be using this company…'
    },
    {

        id: 2,
        name: 'Kristina B',
        date: 'January 24, 2024',
        // place: 'US',
        rating: 5,
        verified: true,
        title: 'The ring came exactly as expected',
        description: 'The ring came exactly as expected. Its stunning. Beautiful quality. Will definitely recommend and use this site again.'
    },
    {

        id: 3,
        name: 'Jeremy Slusher',
        date: 'January 20, 2024',
        // place: 'US',
        rating: 5,
        verified: true,
        title: 'Perfect…',
        description: 'You made this experience absolutely perfect, I can’t find any other words to express how amazing this is...'

    },
    {
        id: 4,
        name: 'Steve Donahue',
        date: 'January 18, 2024',
        // place: 'US',
        rating: 5,
        verified: true,
        title: 'The customer service was excellent and…',
        description: 'The customer service was excellent and the ring quality was outstanding. We will definitely order from Friendly Diamonds again!'
    },
    {
        id: 5,
        name: 'Nate Danner',
        date: 'January 13, 2024',
        // place: 'US',
        rating: 5,
        verified: true,
        title: 'Couldn`t be more pleased !',
        description: 'So far i`ve ordered 2 bands, and an engagement ring from FD, couldn`t be more pleased. my wife loves all of her rings, and FD is always so fast…'
    },
    {

        id: 6,
        name: 'Mel B',
        date: 'January 01, 2024',
        // place: 'US',
        rating: 5,
        verified: true,
        title: 'This is hands down the best online…',
        description: 'This is hands down the best online jewelry company. Customer service and buying experience was awesome. They were helpful…'

    },
    {
        id: 7,
        name: 'Helen H',
        date: 'December, 23, 2023',
        // place: 'US',
        rating: 5,
        verified: true,
        title: 'I thought the website was very easy…',
        description: 'I thought the website was very easy to navigate, making the purchase fun and personal. I felt very safe to make such a big purchase…'

    },
    {
        id: 8,
        name: 'John Heaton',
        date: 'July, 25, 2024',
        // place: 'US',
        rating: 5,
        verified: true,
        title: 'Sparkling investment',
        description: 'The stone itself is beautiful and the setting seems professional and simplistic, Which is all you really need for a well made diamond ring....'

    }
]

</script>

<style scoped>
:global(.trustpilot__swiper .swiper-slide) {
    height: auto !important;
}

.trust_review_block {
    display: flex;
}


.trust_intro_flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.new_trust_intro_flex {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 20%;
    margin-right: 15px;
}

.box2 {
    width: 80%;
}

.trust_title {
    border-right: 1px solid #eaeaea;
    position: relative;
}

.new_trust_title {
    
}

.para {
    border-bottom:  2px solid #eaeaea;
}

.trust_count,
.trust_title {
    padding: 0 40px;
}

.new_trust_count,
.new_trust_title {
    padding: 0 20px;
}

.trust_title .trust_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
}

.new_trust_title .trust_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
}

.trust_title h2 span {
    display: inline-block;
    margin: 10px 0 0 5px;
    font-weight: 600;
    color: #191919;
}

.new_trust_title h2 span {
    display: inline-block;
    margin: 10px 0 0 5px;
    font-weight: 600;
    color: #191919;
}

.trust_title p {
    color: #353535;
    font-size: 16px;
    line-height: normal;
}

.new_trust_title p {
    color: #606060;
    font-size: 14px;
    line-height: normal;
    text-align: center;
    margin-bottom: 10px;
    
}

.star_box {
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.trust_count h3 {
    font-size: 24px;
    color: #212529;
    text-align: center;
    font-weight: 600;
}

.new_trust_count h3 {
    font-size: 20px;
    color: #212529;
    text-align: center;
    font-weight: 600;
}

.trust_count a {
    display: inline-block;
    color: #353535;
    text-decoration: underline;
}

.new_trust_count a {
    display: inline-block;
    color: #353535;
    text-decoration: underline;
}

.trust_count {
    text-align: center;
}

.new_trust_count {
    text-align: center;
    margin-top: 12px;
}

.trust_review_block {
    margin-top: 4%;
}

.slide_box {
    padding: 2px;
}


@media(max-width:1200px){
    .new_trust_count,
    .new_trust_title {
        padding: 0 10px;
    }
}


@media(max-width:992px) {
    

    .new_trust_intro_flex {
        width: 25%;
    }

    .box2 {
        width: 75%;
    }
}


@media(max-width:767px) {
    .new_trust_intro_flex {
        display: none;
    }
    .box2 {
        width: 100%;
    }
}

@media(max-width: 600px) {
   

    .trust_title h2 {
        justify-content: center;
    }

    
    .trust_title p {
        text-align: center;
        font-size: 12px;
    }

  
    .trust_count h3 {
        font-size: 18px;
    }


    .trust_title {
        padding: 0 10px 0 0;
    }
 
    .trust_count a{
        font-size: 12px;
    }
    .trust_count {
        margin-left: 10px;
    }
    .trust_title p br {
        display: none;
    }
}

@media(min-width:768px) {
    .trust_intro_flex {
        display: none;
    }
}
</style>